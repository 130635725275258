<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          SBC
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            欢迎回来! 👋
          </b-card-title>
          <b-card-text class="mb-2">
             请使用管理员分配的账号登陆
          </b-card-text>

          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">公司用户:</span> xxx@xyb2b.com | 工号 | 手机</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">合作伙伴:</span> client@client.com | 手机号码</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'如需账号，请联系管理员'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>

          <!-- form -->
          <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
                label="用户名"
                label-for="username"
            >
              <b-form-input
                  id="username"
                  v-model="username"
                  name="username"
                  placeholder="xxx@xyb2b.com"
              />
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">密码</label>
                <!--
                <b-link :to="{name:'auth-forgot'}">
                  <small>忘记密码？</small>
                </b-link>
                -->
              </div>
              <b-input-group
                  class="input-group-merge"
              >
                <b-form-input
                    id="password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="密码"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                  id="rememberme"
                  v-model="rememberme"
                  name="rememberme"
              >
                记住账号
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
                type="submit"
                variant="primary"
                block
            >
              登录
            </b-button>
          </b-form>

          <!--
          <b-card-text class="text-center mt-2">
            <span>还没有账号？ </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;注册一个</span>
            </b-link>
          </b-card-text>
          -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>

  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
  BAlert, VBTooltip,
} from 'bootstrap-vue'
import useToken from '@/auth/token/useToken'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from "vue";
import router from "@/router";
import App from "@/App";

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      rememberme: true,
      username: '',
      password: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
 /*   updateModal(visible){
      this.loadingVisible = visible;
    },*/
    login() {
      useToken.login({
        username: this.username,
        password: this.password,
      })
          .then(response => {
            console.log(response)
            if(response.data.code == 115 || response.data.code == 114){
              alert(response.data.data)
              return false
            }
            const userData = response.data.data
            useToken.setToken(userData.token)
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)

            store.dispatch('code/all').then(res => {
              Vue.prototype.$code_table = res.data.data

              this.$router.replace(`${userData.return_url}`)
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `欢迎 ${userData.full_name || userData.username}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `您已成功作为 ${userData.role_name} 登陆。`,
                      },
                    });
                  })
                  .catch(error => {
                    alert(error.response.data.error)
                  })
            })

            })


    }
  },

                // ? This is just for demo purpose as well.
                // ? Because we are showing eCommerce app's cart items count in navbar
                //this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                //const return_url = getHomeRouteForLoggedInUser(userData.role)
  setup() {
  },
  created() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
